import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-uttrakhand',
  templateUrl: './uttrakhand.component.html',
  styleUrls: ['./uttrakhand.component.scss']
})
export class UttrakhandComponent implements OnInit {

  s3BaseURL = `${environment.s3BaseUrl}`; 

  constructor() { }

  ngOnInit(): void {}

  public gallery = [
    "./assets/images/gallery/gallery-01.jpeg",    
    "./assets/images/gallery/gallery-03.jpeg",
    "./assets/images/gallery/gallery-04.jpeg",
    "./assets/images/gallery/gallery-05.jpeg",
    "./assets/images/gallery/gallery-11.jpeg",
    "./assets/images/gallery/gallery-12.jpeg",
    "./assets/images/gallery/gallery-13.jpeg",
    "./assets/images/gallery/gallery-14.jpeg",
    "./assets/images/gallery/gallery-15.jpeg",
  ];
}