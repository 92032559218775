import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit {  

  management = [{
      name: "Manish Jugran",
      position: "Chairman",
      image: "./assets/images/members/manish-jugran.jpg"
    }, {
      name: "Avtar Aswal",
      position: "Secretary",
      image: "./assets/images/members/avtar-aswal.jpg"
    }, {
      name: "Priyanka Rawat",
      position: "Secretary",
      image: "./assets/images/members/priyanka-rawat.jpg"
    }, {
      name: "Jitendra Singh Rana",
      position: "Treasurer",
      image: "./assets/images/members/jitendra-singh-rana.jpg"
    }, {
      name: "Krishnapal Negi",
      position: "Director",
      image: "./assets/images/members/krishnapal-negi.jpg"
    }, {
      name: "Prem Singh Patwal",
      position: "Director",
      image: "./assets/images/members/prem-singh-patwal.jpg"
    }, {
      name: "Ramvir Singh Panwar",
      position: "Director",
      image: "./assets/images/members/ramvir-singh-panwar.jpg"
    }, {
      name: "Saukar Rawat",
      position: "Director",
      image: "./assets/images/members/saukar-rawat.jpg"
    }
  ];
  
  coreMembers = [{
      name: "Amit Payal",
      image: "./assets/images/members/amit-payal.jpg"
    },{
      name: "Bipurag Bhandari",
      image: "./assets/images/members/man.jpg"
    },{
      name: "Harshita Seth",
      image: "./assets/images/members/harshita-seth.jpg"
    },{
      name: "Hukum Rawat",
      image: "./assets/images/members/hukum-rawat.jpg"
    },{
      name: "Manoj Pant",
      image: "./assets/images/members/manoj-pant.jpg"
    },{
      name: "Pawan Ghildiyal",
      image: "./assets/images/members/pawan-ghildiyal.jpg"
    },{
      name: "Ravi Juglan",
      image: "./assets/images/members/ravi-juglan.jpg"
    },{
      name: "Shiv Singh Jagwan",
      image: "./assets/images/members/shiv-singh-jagwan.jpg"
    },{
      name: "Surat Rana",
      image: "./assets/images/members/surat-rana.jpg"
    }
  ];
  
  preChairmans = [{
    name: "Laxmikant Joshi",
    image: "./assets/images/members/laxmikant-joshi.jpg",
    tenuareStart: "2020",
    tenuareEnd: "2021"
  },{
    name: "Diwan Singh Bhandari",
    image: "./assets/images/members/diwan-singh-bhandari.jpg",
    tenuareStart: "2016",
    tenuareEnd: "2020"
  },{
    name: "Krishnapal Negi",
    image: "./assets/images/members/krishnapal-negi.jpg",
    tenuareStart: "2013",
    tenuareEnd: "2016"
  }];

  preSecratories = [{
    name: "Pankaj Nautiyal",
    image: "./assets/images/members/pankaj-nautiyal.jpg",
    tenuareStart: "2020",
    tenuareEnd: "2022"
  },{
    name: "Birender Rawat",
    image: "./assets/images/members/birender-rawat.jpg",
    tenuareStart: "2016",
    tenuareEnd: "2020"
  },{
    name: "Saukar Rawat",
    image: "./assets/images/members/saukar-rawat.jpg",
    tenuareStart: "2015",
    tenuareEnd: "2016"
  }];

  preDirectors = [{
    name: "Sandeep Bisht",
    image: "./assets/images/members/sandeep-bisht.jpg",
    tenuareStart: "2022",
    tenuareEnd: "2023"
  },{
    name: "Pankaj Nautiyal",
    image: "./assets/images/members/pankaj-nautiyal.jpg",
    tenuareStart: "2020",
    tenuareEnd: "2022"
  },{
    name: "Naveen Sharma",
    image: "./assets/images/members/naveen-sharma.jpg",
    tenuareStart: "2021",
    tenuareEnd: "2022"
  },{
    name: "Laxmikant Joshi",
    image: "./assets/images/members/laxmikant-joshi.jpg",
    tenuareStart: "2010",
    tenuareEnd: "2021"
  },{
    name: "Diwan Singh Bhandari",
    image: "./assets/images/members/diwan-singh-bhandari.jpg",
    tenuareStart: "2016",
    tenuareEnd: "2020"
  },{
    name: "Birender Rawat",
    image: "./assets/images/members/birender-rawat.jpg",
    tenuareStart: "2016",
    tenuareEnd: "2020"
  },{
    name: "Shiv Singh Jagwan",
    image: "./assets/images/members/shiv-singh-jagwan.jpg",
    tenuareStart: "2018",
    tenuareEnd: "2020"
  },{
    name: "Pushkar Rawat",
    image: "./assets/images/members/pushkar-rawat.jpg",
    tenuareStart: "2018",
    tenuareEnd: "2020"
  },{
    name: "Krishnapal Negi",
    image: "./assets/images/members/krishnapal-negi.jpg",
    tenuareStart: "2013",
    tenuareEnd: "2016"
  },{
    name: "Ramvir Singh Panwar",
    image: "./assets/images/members/ramvir-singh-panwar.jpg",
    tenuareStart: "2015",
    tenuareEnd: "2016"
  },{
    name: "Rajender Singh Rawat",
    image: "./assets/images/members/man.jpg",
    tenuareStart: "2010",
    tenuareEnd: "2015"
  },{
    name: "Narendra Singh",
    image: "./assets/images/members/man.jpg",
    tenuareStart: "2010",
    tenuareEnd: "2012"
  }];

  constructor() { }

  ngOnInit(): void {
    // Fetch the members details from the backend 
    // Seperate them as a Chairman, Secratery, Core Members, Pre Chairmans & Pre Directors

  }
}